<mat-card *ngIf="merchant">
  <mat-card-header>
    <mat-card-title>{{mode}} Merchant</mat-card-title>
    <mat-card-subtitle>
      <p *ngIf="!merchant.lastModifiedBy">created by: {{merchant.createdBy}}</p>
      <p *ngIf="merchant.lastModifiedBy">last modified by: {{merchant.lastModifiedBy}}</p>

      <p *ngIf="!merchant.lastModifiedDateUtc">created at: {{merchant.createdDateUtc * 1000| date:'yyyy-MM-dd hh:mm:ss a z'}}</p>
      <p *ngIf="merchant.lastModifiedDateUtc">last modified at: {{merchant.lastModifiedDateUtc * 1000| date:'yyyy-MM-dd hh:mm:ss a z'}}</p>
    </mat-card-subtitle>
  </mat-card-header>

  <form #merchantForm="ngForm" fxLayout="column" fxFill fxLayoutGap fxLayoutAlign="center center">
    <div fxFill>
      <mat-slide-toggle fxFill name="isActive" [checked]="merchant.isActive"
        [(ngModel)]="merchant.isActive" [disabled]="!isAdmin">
        Active
        <mat-hint> - unchecking will disable the merchant</mat-hint>
      </mat-slide-toggle>
    </div>

    <mat-form-field fxFill>
      <mat-label>MID</mat-label>
      <input matInput required placeholder="MID" name="mid" [(ngModel)]="merchant.mid" [disabled]="!isAdmin || !isCreateMode">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Acquirer Mid</mat-label>
      <input matInput [required]="isCybersource" placeholder="Acquirer Mid" name="acquirerId"
        [(ngModel)]="merchant.acquirerMid" [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Amex Mid</mat-label>
      <mat-hint>Amex Account Type: {{amexAccountType}}</mat-hint>
      <input matInput placeholder="Amex Mid" name="amexMid" [(ngModel)]="merchant.amexMid" [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Locator Id</mat-label>
      <input matInput required placeholder="Locator Id" name="locatorId" [(ngModel)]="merchant.locatorId" [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Description" name="description" [(ngModel)]="merchant.description" [disabled]="!isAdmin"></textarea>
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Gateway</mat-label>
      <mat-select [(value)]="merchant.gatewayName" name="gatewayName" [(ngModel)]="merchant.gatewayName" [disabled]="!isAdmin">
        <mat-option *ngFor="let gateway of gateways" [value]="gateway">
          {{gateway}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="!isCybersource" fxFill>
      <mat-form-field fxFill>
        <mat-label>Gateway Username</mat-label>
        <input matInput required placeholder="Gateway Username" name="gatewayUsername" [(ngModel)]="merchant.gatewayUsername"
          [disabled]="!isAdmin">
      </mat-form-field>

      <mat-form-field fxFill>
        <mat-label>Gateway Password</mat-label>
        <input matInput placeholder="Gateway Password" name="gatewayPassword" [(ngModel)]="merchant.gatewayPassword"
          (focus)="resetPassword()" [disabled]="!isAdmin">
        <mat-hint>Setting this will override what's passed in the access token. Blank means not set,
          ****** is set and
          encrypted. Saving while blank will remove encrypted password
        </mat-hint>
      </mat-form-field>
    </div>

    <mat-form-field fxFill>
      <mat-label>MCC</mat-label>
      <input matInput [required]="isCybersource" placeholder="MCC" name="mcc"
        [(ngModel)]="merchant.mcc" [matAutocomplete]="autoMcc" [formControl]="mccCtrl"
        (keypress)="keyPress($event, autoMcc)"
        [disabled]="!isAdmin">
    </mat-form-field>
    <mat-autocomplete #autoMcc="matAutocomplete" (optionSelected)="onMccSelectionChange($event)">
      <mat-option *ngFor="let mcc of filteredMccOptions | async" [value]="mcc.mcc">
        {{ mcc.mcc }} - {{ mcc.text }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field fxFill>
      <mat-label>Name</mat-label>
      <input matInput required placeholder="Name" name="name" [(ngModel)]="merchant.name" [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Partner Name</mat-label>
      <input matInput required placeholder="Partner Name" name="partnerName"
        [(ngModel)]="merchant.partnerName" [matAutocomplete]="autoPartner" [formControl]="partnerCtrl"
        (keypress)="keyPress($event, autoPartner)"
        [disabled]="!isAdmin">
    </mat-form-field>
    <mat-autocomplete #autoPartner="matAutocomplete" (optionSelected)="onPartnerSelectionChange($event)">
      <mat-option *ngFor="let option of filteredPartnerOptions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field fxFill>
      <mat-label>Remote Id</mat-label>
      <input matInput placeholder="Remote Id" name="remoteId" [(ngModel)]="merchant.remoteId" [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Url</mat-label>
      <input matInput [required]="isCybersource" placeholder="Url" name="url"
        [(ngModel)]="merchant.url" [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email" name="emailAddress" [(ngModel)]="merchant.emailAddress" email="true"
             [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Phone Number</mat-label>
      <input matInput placeholder="Phone Number" name="phoneNumber" [(ngModel)]="merchant.phoneNumber"
             [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Address1</mat-label>
      <input matInput placeholder="Address1" name="address1" [(ngModel)]="merchant.address.address1"
             [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Address2</mat-label>
      <input matInput  placeholder="Address2" name="address2" [(ngModel)]="merchant.address.address2"
             [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>City</mat-label>
      <input matInput placeholder="City" name="city" [(ngModel)]="merchant.address.city"
             [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>State</mat-label>
      <input matInput placeholder="State" name="state" [(ngModel)]="merchant.address.state"
             [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Postcode</mat-label>
      <input matInput placeholder="Postcode" name="postcode" [(ngModel)]="merchant.address.postcode"
             [disabled]="!isAdmin">
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Country Code</mat-label>
      <input matInput placeholder="Country Code" name="countryCode" [(ngModel)]="merchant.countryCode" [disabled]="!isAdmin"
             [maxLength]="2">
      <mat-hint>The two-letter ISO 3166 country code, e.g. GB for the United Kingdom.
        It defaults to GB if not provided or if provided an invalid country code</mat-hint>
    </mat-form-field>

    <mat-slide-toggle fxFill
      name="isMerchantInitiatedTransactionsEnabled"
      [checked]="merchant.isMerchantInitiatedTransactionsEnabled"
      [(ngModel)]="merchant.isMerchantInitiatedTransactionsEnabled"
      [disabled]="!isAdmin">
      Enable Merchant Initiated Transactions
    </mat-slide-toggle>

    <mat-slide-toggle fxFill
      name="isMarsPayfacEnabled"
      *ngIf="isDojo"
      [checked]="merchant.isMarsPayfacEnabled"
      [(ngModel)]="merchant.isMarsPayfacEnabled"
      [disabled]="!isAdmin">
      Enable Mars Gateway for Amex
    </mat-slide-toggle>

    <mat-slide-toggle fxFill
      name="isMarsVisaEnabled"
      *ngIf="isDojo"
      [checked]="merchant.isMarsVisaEnabled"
      [(ngModel)]="merchant.isMarsVisaEnabled"
      [disabled]="!isAdmin">
      Enable Mars Gateway for Visa
    </mat-slide-toggle>

    <mat-slide-toggle fxFill
      name="isMarsMastercardEnabled"
      *ngIf="isDojo"
      [checked]="merchant.isMarsMastercardEnabled"
      [(ngModel)]="merchant.isMarsMastercardEnabled"
      [disabled]="!isAdmin">
      Enable Mars Gateway for Mastercard
    </mat-slide-toggle>

    <mat-slide-toggle fxFill
      name="isMotoEnabled"
      [checked]="merchant.isMotoEnabled"
      [(ngModel)]="merchant.isMotoEnabled"
      [disabled]="!isAdmin">
      Enable Moto Payments
      <mat-hint> - this will also need to be enabled in the gateway</mat-hint>
    </mat-slide-toggle>

    <mat-slide-toggle fxFill
      name="isGooglePayEnabled"
      [checked]="merchant.isGooglePayEnabled"
      [(ngModel)]="merchant.isGooglePayEnabled"
      [disabled]="!isAdmin">
      Enable Google Pay Payments
      <mat-hint> - this will also need to be enabled in the gateway</mat-hint>
    </mat-slide-toggle>

    <mat-slide-toggle fxFill
      name="isApplePayEnabled"
      [checked]="merchant.isApplePayEnabled"
      [(ngModel)]="merchant.isApplePayEnabled"
      [disabled]="!isAdmin">
      Enable Apple Pay Payments
      <mat-hint> - this will also need to be enabled in the gateway</mat-hint>
    </mat-slide-toggle>

    <mat-form-field fxFill *ngIf="merchant.isApplePayEnabled">
      <mat-label>Domain Names</mat-label>
      <mat-chip-grid #chipList aria-label="Domain Name selection">
        <mat-chip-row
          *ngFor="let domainName of merchant.domainNames"
          [selectable]="isAdmin"
          [removable]="isAdmin"
          (removed)="removeDomainName(domainName)"
          [disabled]="isViewer">
          <mat-icon class="domain-registered-icon" *ngIf="isDomainRegistered(domainName)">verified_user</mat-icon>
          <mat-icon class="domain-registered-icon" *ngIf="!isDomainRegistered(domainName)">error</mat-icon>
          {{domainName}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="New domain name..."
          #domainNameInput
          [formControl]="domainNamesCtrl"
          [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="addDomainName($event)"
          [disabled]="isViewer">
      </mat-chip-grid>
      <mat-hint>Use comma, space, tab or enter for a valid input</mat-hint>
    </mat-form-field>

    <mat-expansion-panel [expanded]="true" fxFill *ngIf="!isCreateMode">
      <mat-expansion-panel-header>
        <mat-panel-title>
          API Keys
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-table #table [dataSource]="dataSource" matSort fxFill>
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Key Column -->
        <ng-container matColumnDef="key">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Key</mat-header-cell>
          <mat-cell *matCellDef="let row" >{{row.key}}</mat-cell>
        </ng-container>

        <!-- IsActive Column -->
        <ng-container matColumnDef="isActive">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Is Active</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.isActive}}</mat-cell>
        </ng-container>

        <!-- Created Date Time Column -->
        <ng-container matColumnDef="createdDateUtc">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Created Date Time </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.createdDateUtc * 1000| date:'yyyy-MM-dd hh:mm:ss a z' }} </mat-cell>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <button mat-icon-button color="primary" (click)="generateApiKey()" matTooltip="Generate" [disabled]="!isAdmin">
              <mat-icon aria-label="Generate">add</mat-icon>
            </button>
          </mat-header-cell>

          <mat-cell *matCellDef="let row; let i=index;">
            <button mat-icon-button color="accent" (click)="editApiKey(i, row)" matTooltip="Edit" [disabled]="!isAdmin">
              <mat-icon aria-label="Edit">edit</mat-icon>
            </button>

            <button mat-icon-button color="accent" (click)="deleteApiKey(i, row)" *ngIf="!row.key" matTooltip="Delete">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>

            <button mat-icon-button color="accent" [cdkCopyToClipboard]="row.jwtToken" *ngIf="row.jwtToken" matTooltip="Copy JWT Token">
              <mat-icon aria-label="Copy JWT Token">content_copy</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

       <mat-hint>The Key will be generated on create/update</mat-hint>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true" fxFill *ngIf="merchant.applePayPaymentProcessingCertificates && merchant.applePayPaymentProcessingCertificates.length > 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Apple Pay Payment Processing Certificates
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-table #table [dataSource]="applePayProcessingCertsDataSource" matSort fxFill>
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Merchant ID Column -->
        <ng-container matColumnDef="merchantId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Merchant ID</mat-header-cell>
          <mat-cell *matCellDef="let row" >{{row.merchantId}}</mat-cell>
        </ng-container>

        <!-- Creation Date Time Column -->
        <ng-container matColumnDef="creationDateTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Creation Date Time </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.creationDateTime | date:'yyyy-MM-dd hh:mm:ss a z' }} </mat-cell>
        </ng-container>

        <!-- Expiration Date Time Column -->
        <ng-container matColumnDef="expirationDateTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Expiration Date Time </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.expirationDateTime | date:'yyyy-MM-dd hh:mm:ss a z' }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="applePayProcessingCertsDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: applePayProcessingCertsDisplayedColumns;"></mat-row>
      </mat-table>

       <mat-hint>Apple Pay Payment Processing Certificates used to process Apple Pay payments using mobile SDK</mat-hint>
    </mat-expansion-panel>

    <mat-card-actions fxFill>
      <div fxLayout="row" fxLayoutAlign="start" fxFlex="30%">
        <a routerLink="/merchant" [state]="{merchant: merchant}" mat-raised-button
        [disabled]="isCreateMode || !isAdmin || isProcessing"
        *ngIf="isAdmin">Duplicate</a>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" fxFlex="70%">
        <a routerLink="/merchants" mat-raised-button>Cancel</a>
        <button *ngIf="isCreateMode && isAdmin" color="primary" mat-raised-button (click)="submit()"
          [disabled]="!merchantForm.valid || isProcessing">Create</button>
        <button *ngIf="isUpdateMode && isAdmin" color="primary" mat-raised-button (click)="update()"
          [disabled]="!(merchantForm.dirty || domainNamesCtrl.dirty || apiKeysCtrl.dirty || mccCtrl.dirty || partnerCtrl.dirty) || !merchantForm.valid || isProcessing">Update</button>
        <button *ngIf="isUpdateMode && isApplePayDomainAdder" color="primary" mat-raised-button (click)="updateAddDomainNames()"
          [disabled]="!domainNamesCtrl.dirty || isProcessing">Update Domain Names</button>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
